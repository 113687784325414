<template>
  <transition name="menu">
    <form-organization :is-edit="true"/>
  </transition>
</template>

<script>
import formOrganization from './components/formOrganization'

export default {
  name: 'EditformOrganization',
  components: { formOrganization }
}
</script>

